import axios from 'axios';

const queryParams = (params) => {
  params = params || {};
  const query = Object.keys(params).length
    ? Object.keys(params)
        .map((key) => {
          return key + '=' + params[key];
        })
        .join('&')
    : false;

  return query ? `?${query}` : '';
};

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  withCredentials: true,
  timeout: process.env.NODE_ENV === 'development' ? 90000 : 30000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

// leave the export, even if you don't use it
export default ({ Vue }) => {
  Vue.prototype.$axios = axiosInstance;
};

export { axiosInstance, queryParams };
