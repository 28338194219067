// eslint-disable-next-line
export function isLoading(state) {
  return state.isLoading;
}

export function hasError(state) {
  return state.error !== false;
}

export function error(state) {
  return state.error;
}
