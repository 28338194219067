import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;

// Import our Stylesheets
import './styles/index.scss';

// make router instance available in store
store.$router = router;

// Import any Plugins we want globally available in the application
import axios from './plugins/axios';
import errorProcessor from './plugins/error-processor';
import fontAwesome from './plugins/font-awesome';

// Run Plugin functions
axios({ store, Vue });
errorProcessor({ Vue });
fontAwesome({ Vue });

new Vue({
  el: '#app',
  router,
  store,
  render: (h) => h(App),
});
