// eslint-disable-next-line
export function isLoading(state) {
  return state.isLoading;
}

export function currentBooking(state) {
  return state.currentBooking;
}

export function currentBookingNumbers(state) {
  if (!state.currentBooking) {
    return null;
  }

  return state.currentBooking.map((booking) => {
    return booking.BookingNumber;
  });
}

export function currentBookingTotal(state) {
  if (!state.currentBooking) {
    return null;
  }

  return state.currentBooking.reduce((total, booking) => {
    if (booking.PaymentStatus.toUpperCase() === 'PARTLYPAID') {
      return (total += booking.AmountToPay);
    }
    if (booking.PaymentStatus.toUpperCase() === 'UNPAID') {
      return (total += booking.AmountToPay);
    }

    return total;
  }, 0);
}

export function currentBookingIsPartial(state) {
  if (!state.currentBooking) {
    return false;
  }

  return (
    state.currentBooking.filter((booking) => {
      return booking.PaymentStatus.toUpperCase() === 'PARTLYPAID';
    }).length > 0
  );
}

export function currentBookingTotalToPay(state) {
  if (!state.currentBooking) {
    return null;
  }

  return state.currentBooking.reduce((total, booking) => {
    return (total += booking.TotalPriceToPay);
  }, 0);
}
