import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import bookit from './bookit';
import ui from './ui';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    bookit,
    ui,
  },
});
